import React from 'react';
import {Box, Typography, Divider, Button} from '@mui/material';
import Context from '../context/Context';
import FirstLayout from './Firstimagelayout';
import SecondLayout from './Secondimagelayout';
import Bags from './Bags';
import Bottom from './Bottom';
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  const ctx = React.useContext(Context);
  return (
    <div style={{marginTop: '1px' }}>
      {ctx.currWidth() > 600 ? ctx.threeimagechoice ? (<FirstLayout/>) : (<SecondLayout/>) : (<></>)}
        <Box display={ctx.currWidth() <= 600 ? 'flex' : 'none'} flexDirection="column" id="smallbox">
            <Box flex="1" id="smallfirstbox" style={{ minHeight: '240px', maxHeight: '240px', overflow: 'hidden'}}>
              <img
                  src={require("../images/Latte.jpg")}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  id="smallfirstimage"
                  alt=""
              />
              <div style={{ position: 'relative', top: '-120px', width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 auto'}}>
                <Typography variant="h4" component="h2" sx={{color: 'white', fontSize: '20px'}} fontWeight="bold" align="center">
                  The perfect cup of coffee, in the comfort of your home
                </Typography>
                <button
                  style={{
                    backgroundColor: '#808080',
                    color: '#FFF',
                    padding: '10px 20px',
                    borderRadius: '20px',
                    border: 'none',
                    cursor: 'pointer',
                    marginTop: '10px'
                  }}
                  onClick={() => navigate('/Shop')}
                >
                  Browse selection
                </button>
              </div>
            </Box>
            <Box flex="1" id="smallsecondbox" style={{ minHeight: '240px', maxHeight: '240px', overflow: 'hidden'}}>
              <img
                  src={require("../images/Roaster.jpg")}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  id="smallsecondimage"
                  alt=""
              />
              <div style={{ position: 'relative', top: '-100px', width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 auto' }}>
                <Typography variant="h4" component="h2" sx={{color: '#FFF8E1', fontSize: '20px'}} fontWeight="bold" align="center">
                  Our Journey, Your Perfect Cup: Discover our decades-long <a href="/OurStory" style={{ color: '#FFF8E1', textDecoration: 'underline' }}>process</a>
                </Typography>
                <Typography variant="h4" component="h2" sx={{color: '#FFF8E1', fontSize: '20px'}} fontWeight="bold" align="center">
                  and <a href="/AdditionalServices" style={{ color: '#FFF8E1', textDecoration: 'underline' }}>additional services</a>
                </Typography>
              </div>
            </Box>
        </Box>

        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
          <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
          <Typography variant="h4" component="h2" sx={{color: '#5C4033', fontSize: '24px', fontWeight: 'bold', margin: '0 10px', whiteSpace: 'nowrap'}}>
            Our Selection
          </Typography>
          <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
        </div>
        <Bags all="False"/>
        <Button
          variant="contained" 
          color="primary" 
          sx={{
              borderRadius: '25px', // Adjust the border radius as needed
              width: '300px', // Adjust the width as needed
              height: '60px', // Adjust the height as needed
              padding: '15px 30px', // Increased padding for a larger button
              fontSize: '18px', // Larger font size
              border: 'none',
              cursor: 'pointer',
              marginTop: '10px',
              marginBottom: '20px',
              display: 'block', // This will help in centering
              marginLeft: 'auto',
              marginRight: 'auto'
          }}
        onClick={() => navigate('/Shop')}
        >
          
          Browse selection
        </Button>

        <Bottom/>
    </div>
  );
};
export default Home;
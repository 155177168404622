import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { Badge, Button } from '@mui/material';
import logo from '../images/logo.png';
import { useNavigate } from "react-router-dom";
import Context from '../context/Context';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
const pages = ['Home', 'Shop', 'Additional Services', 'Our Story', 'Contact Us'];
const links = {'Home': '/Home', 'Additional Services': '/AdditionalServices', 'Our Story': '/OurStory', 'Shop': '/Shop', 'Cart': '/Cart', 'Contact Us': '/ContactUs'};

function TopBar() {
  const ctx = React.useContext(Context);
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const changelink = (oneclicked) => {
    // console.log(oneclicked);
    navigate(links[oneclicked]);
    handleCloseNavMenu();
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: '#DFCAA0' }}>
      <Container maxWidth="100vw">
        <Toolbar disableGutters>
          <img
              src={logo}
              alt="Logo"
              style={{
              display: { xs: 'none', md: 'flex' },
              marginRight: '1rem', // Adjust spacing as needed
              width: 'auto',
              height: '5rem', // Adjust height as needed
              marginTop: '1rem'
              }}
              onClick={() => changelink('Home')}
          />
          <Typography
            variant="h5"
            component="a"
            sx={{
              mr: 2,
              display: 'flex',
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '#5C4033',
              textDecoration: 'none',
              fontSize: ctx.showAppButtons() ? 31 : '3vw'
            }}
          >
            Perfect Pour Espresso
          </Typography>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: "flex-end"}}>
          <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => changelink('Cart')}
              color="#5C4033"
            >
              <Badge badgeContent={ctx.cartsize} color="primary">
                <ShoppingCartIcon/>
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#5C4033"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                '.MuiPaper-root': { // Target the Paper component
                  //backgroundColor: '#DFCAA0', // Not sure if I like how this looks, for now will keep white
                },
                display: 'block'
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => changelink(page)}>
                  <Typography textAlign="center"sx={{color: '#5C4033'}}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopBar;

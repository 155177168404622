// import {useState, useEffect} from 'react';
import React from 'react';
import { Card, Typography } from '@mui/material';
import {
  Elements,
  LinkAuthenticationElement,
  PaymentElement,
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Context from '../context/Context';
import swal from 'sweetalert';
import Cookies from 'js-cookie';

const PaymentForm = () => {
  const ctx = React.useContext(Context);
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [name, setName] = React.useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "",
      },
      redirect: 'if_required',
    });

    if (error) {
      // handle error
    //   setMessages(`${messages}<br />${error.message}`);
    } else {
      const requestBody = {
        name: name,
        email: email,
        order: ctx.cartitems,
        address: `${address.line1}, ${address.city}, ${address.state} ${address.postal_code}, ${address.country}`
      };
      try {
        const response = await fetch("https://m7t02vzvdf.execute-api.us-east-1.amazonaws.com/dev/confirm", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            body: JSON.stringify(requestBody),
          }),
        });

        if (response.status === 200) {
          swal({
            title: "Order Successful!",
            text: "Thank you for your purchase!",
            icon: "success",
            button: "Continue",
          }).then(() => {
            // window.location.href = 'https://www.google.com';
            Cookies.remove('cart');
            window.location.href = '/Home';
          });
        } else {
        //   setMessages(`${messages}<br />Failed to send order confirmation email: ${data.message}`);
        }
      } catch (err) {
        // setMessages(`${messages}<br />Error: ${err.message}`);
      }


      // swal({
      //   title: "Order Successful!",
      //   text: "Thank you for your purchase!",
      //   icon: "success",
      //   button: "Continue",
      // })
      // .then(() => {
      //   window.location.href = 'https://www.google.com';
      // });
      // Redirect to Google
      
    }
  };

  return (
    <div className="sr-root">
      <div className="sr-main">
        <h1
        style={{
            color: '#5C4033',
            fontFamily: 'Playfair Display, serif'
        }}
        >
            Finish Your Purchase
        </h1>

        <form onSubmit={handleSubmit}>
          <h3
          style={{
            color: '#5C4033',
            fontFamily: 'Playfair Display, serif'
          }}
          >
            Contact info
          </h3>
          <LinkAuthenticationElement
            // Access the email value like so:
            onChange={(event) => {
             setEmail(event.value.email);
            }}
            //
            // Prefill the email field like so:
            // options={{defaultValues: {email: 'foo@bar.com'}}}
          />

          <h3
          style={{
            color: '#5C4033',
            fontFamily: 'Playfair Display, serif'
          }}
          >
            Shipping address
          </h3>
          <AddressElement
            options={{mode: 'shipping', allowedCountries: ['US']}}

            // Access the address like so:
            onChange={(event) => {
              setAddress(event.value.address);
              setName(event.value.name);
            }}
          />

          <h3
          style={{
          color: '#5C4033',
          fontFamily: 'Playfair Display, serif'
          }}
          >
            Payment
          </h3>
          <PaymentElement />
          <button
            type="submit"
            style={{
            backgroundColor: '#DFCAA0', // primary color from Material-UI
            color: '#5C4033', // white text color
            borderRadius: '25px', // Adjust the border radius as needed
            width: '300px', // Adjust the width as needed
            height: '60px', // Adjust the height as needed
            padding: '15px 30px', // Increased padding for a larger button
            fontSize: '18px', // Larger font size
            border: 'none',
            cursor: 'pointer',
            marginTop: '10px',
            display: 'block', // This will help in centering
            textAlign: 'center' // Ensure the text is centered
            }}
          >
            Complete Order
          </button>
        </form>
      </div>
    </div>
  );
}

// Customize the appearance of Elements using the Appearance API.
const appearance = {
  theme: 'stripe',
  variables: {
    colorPrimary: '#ed5f74',
    borderRadius: '20px',
    fontFamily: '--body-font-family: -apple-system, BlinkMacSystemFont, sans-serif',
    colorBackground: '#fafafa',
  },
};

const StripeCheckoutForm = () => {
  const [clientSecret, setClientSecret] = React.useState(null);
  const ctx = React.useContext(Context);
//   console.log(ctx.cartitems);
  React.useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("https://m7t02vzvdf.execute-api.us-east-1.amazonaws.com/dev/intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        body: JSON.stringify({
          cart: ctx.cartitems
        })
      })
    })
      .then((res) => res.json())
      .then((response) => {
        const { statusCode, body } = response;
        if (statusCode === 200) {
          const parsedBody = JSON.parse(body);
          const { clientSecret } = parsedBody;
          console.log(clientSecret);
          setClientSecret(clientSecret);
        } else {
          console.error("Error in response", response);
        }
      });
      // .then(({clientSecret}) => setClientSecret(clientSecret));
  }, [ctx.cartitems]);

  if(clientSecret) {
    return (
        <Card sx={{ margin: 2, padding: 2 }}>
            <Elements stripe={ctx.stripePromise} options={{clientSecret, appearance}}>
                <PaymentForm />
            </Elements>
        </Card>
    )
  } else {
    return (
        <Card sx={{ margin: 2, padding: 2 }}>
            <Typography>
                Loading...
            </Typography>
        </Card>
    )
  }
};

export default StripeCheckoutForm;

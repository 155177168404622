import React from 'react';
import Context from '../context/Context';
import { Card, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Box } from '@mui/material';

const CheckoutItems = () => {
    const ctx = React.useContext(Context);
    let weight = ctx.cartitems.reduce((total, item) => total + (item.quantity * item.size), 0)
    let finalcost = 0;
    let shipping = "$4.00";
    if (weight < 5) {
        finalcost = finalcost + 4.00;
    } else {
        shipping = "free";
    }
    finalcost = finalcost + Number(ctx.cartitems.reduce((total, item) => total + (item.cost * item.quantity * item.size), 0).toFixed(2))
    finalcost = finalcost.toFixed(2)
    return (
        <Card sx={{ margin: 2, padding: 2 }}>
            <Typography variant="h4" component="div" sx={{ fontFamily: 'Playfair Display, serif', color: '#5C4033'}}>
                Your Items
            </Typography>
            <List>
                {ctx.cartitems.map((item, index) => (
                    <ListItem key={index} divider>
                        <ListItemAvatar>
                            <Avatar alt={item.name} src={require(`../CoffeeImages/${item.image_name}`)} sx={{ mr: 2 }} />
                        </ListItemAvatar>
                        <ListItemText 
                            primary={<Typography color="primary" fontWeight="bold" style={{ color: '#5C4033', fontSize: '20px' }} >{item.name}</Typography>}
                            primaryTypographyProps={{ flex: 1 }}
                            secondary={
                                <Box component="span" display="block">
                                    <b>Size:</b> {item.size} lb <b>Quantity:</b> {item.quantity}<br />
                                    <b>Grind:</b> {item.grind}
                                </Box>
                            }
                        />
                        <Typography variant="body1" component="span">
                            ${(item.cost * item.quantity * item.size).toFixed(2)}
                        </Typography>
                    </ListItem>
                ))}
            </List>
            <Typography variant="h6" component="div">
                Subtotal: ${ctx.cartitems.reduce((total, item) => total + (item.cost * item.quantity * item.size), 0).toFixed(2)}
            </Typography>
            <Typography variant="h6" component="div">
                Shipping: {shipping}
            </Typography>
            <Typography variant="h5" component="div">
                Total: ${finalcost}
            </Typography>
        </Card>
    );
};

export default CheckoutItems;
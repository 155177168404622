import React, { useState, useEffect } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, SwipeableDrawer, Button, MenuItem, InputLabel, Select, Input, Divider, Grid} from '@mui/material';
import Context from '../context/Context';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
const Bags = ({ all }) => {
    const ctx = React.useContext(Context);
    
    const [coffees, setCoffees] = useState([]);
    const [grind, setGrind] = useState('Whole Bean');
    const [size, setSize] = useState(1);
    const [quantity, setQuantity] = useState(1);
    const [selectedItem, setSelectedItem] = useState({name: 'Espresso', image_name: 'Espresso.png', cost: 0, visible: false});
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    useEffect(() => {
      import('../Coffees/Coffees.json')
        .then(data => {
          if (all == "False") {
            setCoffees(data.coffees.slice(0, 6));
          }  else {
            setCoffees(data.coffees);
          }
        })
        .catch(error => {
          console.error('Error loading Coffees:', error);
        });
    }, []);

    const handleClick = (item) => {
        setGrind('Whole Bean');
        setSize(1);
        setQuantity(1);
        setSelectedItem(item);
    };

    const handleClose = () => {
        setSelectedItem({...selectedItem, visible: false});
    };

    const addToCart = () => {
        const cartItem = {
            name: selectedItem.name,
            image_name: selectedItem.image_name,
            cost: selectedItem.cost,
            grind: grind,
            size: size,
            quantity: Number(quantity)
        };
    
        const rawCookie = Cookies.get('cart');
        let existingCart = rawCookie ? JSON.parse(rawCookie) : [];
        // console.log(existingCart);
        // Check if an item with the same name, size, and grind already exists in the cart
        const existingItemIndex = existingCart.findIndex(item => (
            item.name === cartItem.name && 
            item.size === cartItem.size && 
            item.grind === cartItem.grind
        ));
    
        if (existingItemIndex !== -1) {
            // If the item already exists, update its quantity
            existingCart[existingItemIndex].quantity += cartItem.quantity;
        } else {
            // If the item doesn't exist, add it to the cart
            existingCart.push(cartItem);
        }
        // console.log(existingCart);
        // Update the cart in the cookies
        Cookies.set('cart', JSON.stringify(existingCart));
        ctx.updateCart();
        handleClose();
        setShowConfirmationDialog(true);
    };
    

    const handleConfirmation = (proceed) => {
        setShowConfirmationDialog(false); // Close the confirmation dialog
        if (proceed) {
            window.location.href = '/cart'
        }
    };
  
    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px'}}>
            {coffees.map(coffee => (
                <Box 
                key={coffee.name}  
                width={
                    ctx.currWidth() <= 600 ? 'calc(100% - 40px)' :
                    ctx.currWidth() <= 1100 ? 'calc(40% - 40px)' :
                    'calc(25% - 40px)' 
                }
                p={2} 
                bgcolor="#f0f0f0"
                borderRadius={6}
                textAlign="center"
                boxShadow={3}
                style={{ margin: '20px', position: 'relative', overflow: 'hidden' }}
                sx={{ '&:hover': { backgroundColor: '#f8f8f8', opacity: 0.8},}}
                onClick={() => handleClick(coffee)}
                >
                    <img
                        src={require(`../CoffeeImages/${coffee.image_name}`)} // Assuming images are named accordingly
                        alt={coffee.name}
                        style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                    />
                    <Typography variant="h5" align="center" color="#5C4033" fontWeight="bold" style={{ marginTop: '-30px' }}>{coffee.name}</Typography>
                    <Typography variant="body1" align="center" color="#5C4033" style={{ marginTop: '-10px' }}>{coffee.short_description}</Typography>
                    <div
                        style={{
                        position: 'absolute',
                        top: '-60%',
                        left: '0',
                        width: '100%',
                        // height: '50%',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        padding: '10px',
                        borderRadius: '6px',
                        transition: 'top 0.3s ease',
                        zIndex: '1',
                        overflow: 'hidden',
                        }}
                    >
                        <Typography variant="body1" align="left" color="#5C4033" style={{width: '90%'}}>
                        {coffee.description}
                        </Typography>
                    </div>
                    <div
                        style={{
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        zIndex: 100,
                        }}
                        onMouseEnter={e => { e.currentTarget.previousSibling.style.top = '0'; }}
                        onMouseLeave={e => { e.currentTarget.previousSibling.style.top = '-60%'; }}
                    />
                </Box>
            ))}
            {ctx.currWidth() <= 600 ? (
                <SwipeableDrawer
                anchor="bottom"
                open={selectedItem.visible}
                onClose={handleClose}
                onOpen={() => {}}
                PaperProps={{
                    sx: {
                        borderRadius: '16px 16px 0 0', // Rounded corners for top-left and top-right
                    },
                }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="right" p={1} sx={{ paddingBottom: 0 }}>
                            <IconButton
                            color="inherit"
                            sx={{
                                shape: 'circle',
                                border: '3px solid #5C4033',
                                '&:hover': {
                                    color: '#fff',
                                    backgroundColor: '#5C4033',
                                    border: '3px solid #DFCAA0',
                                    '& svg > path': {
                                        color: '#DFCAA0',
                                    },
                                },
                                marginLeft: 'auto',
                            }}
                            onClick={handleClose}
                            >
                                <CloseIcon style={{ color: '#5C4033' }} />
                            </IconButton>
                    </Box>
                    <Box p={2} display="flex" justifyContent="center" alignItems="center" sx={{ paddingTop: 0 }}>
                        <img 
                            src={require(`../CoffeeImages/${selectedItem.image_name}`)} 
                            alt={selectedItem.name} 
                            style={{ 
                                width: 'auto',
                                height: '250px'
                            }} 
                        />
                    </Box>
                    <Box p={2}>
                        <Typography variant="h2" sx={{ marginBottom: 0, fontSize: '10vw' }} fontWeight="bold" color="#5C4033">{selectedItem.name}</Typography>
                        <Typography variant="body1" sx={{ marginTop: -1, fontSize: '90%' }} color="#5C4033">{selectedItem.short_description}</Typography>
                        <Divider style={{ width: '100%', backgroundColor: '#5C4033', height: '1px' }} color="#5C4033"/>
                        <Typography variant="body2" sx={{ marginTop: 1, fontSize: '90%' }} color="#5C4033">{selectedItem.description}</Typography>
                        <Box mt={3}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="body1" sx={{ marginTop: -1, fontSize: '90%' }} color="#5C4033">
                                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Grind:</span> {grind}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}> 
                                    <InputLabel 
                                        id="size-label"
                                        sx={{
                                            color: '#5C4033' // Set text color to #5C4033
                                        }}
                                    >
                                        Size
                                    </InputLabel>
                                    <Select
                                        labelId="size-label" 
                                        value={size}
                                        onChange={(e) => setSize(e.target.value)}
                                        fullWidth
                                        sx={{
                                            color: '#5C4033' // Set text color to #5C4033
                                        }}
                                    >
                                        {ctx.sizes.map(option => (
                                            <MenuItem key={option} value={option}>{option} lb</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel 
                                        htmlFor="quantity" 
                                        sx={{ 
                                            whiteSpace: 'normal', 
                                            overflowWrap: 'break-word',
                                            color: '#5C4033' // Set text color to #5C4033
                                        }}
                                    >
                                        Quantity - ${selectedItem.cost.toFixed(2)} per pound
                                    </InputLabel>
                                    <Input
                                        id="quantity"
                                        type="number"
                                        value={quantity}
                                        onChange={(e) => setQuantity(e.target.value)}
                                        inputProps={{ min: 1 }}
                                        fullWidth
                                        sx={{
                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                            borderRadius: '4px',
                                            padding: '10px',
                                            color: '#5C4033' // Set text color to #5C4033
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{marginTop: '-10px'}}>
                                    <Typography variant="body1" sx={{ fontSize: '90%' }} color="#5C4033">{"*Free shipping for orders 5 lb or more"}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={3} sx={{ textAlign: 'center' }}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                sx={{
                                    borderRadius: '25px', // Adjust the border radius as needed
                                    width: '50%', // Adjust the width as needed
                                    height: '60px', // Adjust the height as needed
                                    minWidth: '200px'
                                }}
                                onClick={addToCart}
                            >
                                Add to Cart - ${(size * quantity * selectedItem.cost).toFixed(2)}
                            </Button>
                        </Box>
                    </Box>
                </SwipeableDrawer>
            ) : (
                <Dialog open={selectedItem.visible} onClose={handleClose} maxWidth="md" fullWidth>
                    <Box display="flex" justifyContent="space-between" alignItems="right" p={2} sx={{ paddingBottom: 0 }}>
                        <IconButton
                        color="inherit"
                        sx={{
                            shape: 'circle',
                            border: '3px solid #5C4033',
                            '&:hover': {
                                color: '#fff',
                                backgroundColor: '#5C4033',
                                border: '3px solid #DFCAA0',
                                '& svg > path': {
                                    color: '#DFCAA0',
                                },
                            },
                            marginLeft: 'auto',
                        }}
                        onClick={handleClose}
                        >
                            <CloseIcon style={{ color: '#5C4033' }} />
                        </IconButton>
                    </Box>
                    <Box p={2} display="flex" sx={{ paddingTop: 0 }}>
                        <img 
                            src={require(`../CoffeeImages/${selectedItem.image_name}`)} 
                            alt={selectedItem.name} 
                            style={{ 
                                width: 'auto',
                                height: '300px',
                                marginRight: 20 
                            }} 
                        />
                        <Box>
                            <Typography variant="h2" sx={{ marginBottom: 0, fontSize: '4vw' }} fontWeight="bold" color="#5C4033">{selectedItem.name}</Typography>
                            <Typography variant="body1" sx={{ marginTop: -1, fontSize: '90%' }} color="#5C4033">{selectedItem.short_description}</Typography>
                            <Divider style={{ width: '100%', backgroundColor: '#5C4033', height: '1px' }} color="#5C4033"/>
                            <Typography variant="body2" sx={{ marginTop: 1, fontSize: '90%' }} color="#5C4033">{selectedItem.description}</Typography>

                            <Box mt={3}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item sm={12} md={12}> 
                                        <Typography variant="body1" sx={{ marginTop: -1, fontSize: '90%' }} color="#5C4033">
                                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Grind:</span> {grind}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12} md={5}> 
                                        <InputLabel 
                                            id="size-label"
                                            sx={{
                                                color: '#5C4033' // Set text color to #5C4033
                                            }}
                                        >
                                            Size
                                        </InputLabel>
                                        <Select
                                            labelId="size-label" 
                                            value={size}
                                            onChange={(e) => setSize(e.target.value)}
                                            fullWidth
                                            sx={{
                                                color: '#5C4033' // Set text color to #5C4033
                                            }}
                                        >
                                            {ctx.sizes.map(option => (
                                                <MenuItem key={option} value={option}>{option} lb</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item sm={12} md={5}>
                                        <InputLabel 
                                            htmlFor="quantity" 
                                            sx={{ 
                                                whiteSpace: 'normal', 
                                                overflowWrap: 'break-word',
                                                color: '#5C4033' // Set text color to #5C4033
                                            }}
                                        >
                                            Quantity - ${selectedItem.cost.toFixed(2)} per pound
                                        </InputLabel>
                                        <Input
                                            id="quantity"
                                            type="number"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            inputProps={{ min: 1 }}
                                            fullWidth
                                            sx={{
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                borderRadius: '4px',
                                                padding: '10px',
                                                color: '#5C4033' // Set text color to #5C4033
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={5}></Grid>
                                    <Grid item sm={12} md={5}>
                                        <Typography variant="body1" sx={{fontSize: '90%', marginTop: '-16px' }} color="#5C4033">{"*Free shipping for orders 5 lb or more"}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={3} sx={{ textAlign: 'center' }}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    sx={{
                                        borderRadius: '25px', // Adjust the border radius as needed
                                        width: '50%', // Adjust the width as needed
                                        height: '60px', // Adjust the height as needed
                                        minWidth: '200px'
                                    }}
                                    onClick={addToCart}
                                >
                                    Add to Cart - ${(size * quantity * selectedItem.cost).toFixed(2)}
                                </Button>
                            </Box>

                        </Box>
                    </Box>
                </Dialog>

            )}

            <Dialog 
                open={showConfirmationDialog} 
                onClose={() => handleConfirmation(false)} 
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "500px",
                        // maxWidth: "40%", 
                        minWidth: "290px"
                      },
                    },
                  }}
            >
                    <DialogTitle sx={{ backgroundColor: '#DFCAA0', fontSize: '1.5rem'}} color='#5C4033' fontWeight="bold">
                        Added to your cart 
                        <IconButton
                        color="inherit"
                        sx={{
                            shape: 'circle',
                            border: '3px solid #5C4033',
                            '&:hover': {
                                color: '#fff',
                                backgroundColor: '#5C4033',
                                border: '3px solid #DFCAA0',
                                '& svg > path': {
                                    color: '#DFCAA0',
                                },
                            },
                            position: 'absolute',
                            right: '10px',
                        }}
                        onClick={() => handleConfirmation(false)}
                        >
                            <CloseIcon style={{ color: '#5C4033' }} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4} md={3}> {/* Adjust the xs and md values as needed */}
                                <img 
                                    src={require(`../CoffeeImages/${selectedItem.image_name}`)} 
                                    alt={selectedItem.name} 
                                    style={{ 
                                        width: '100%', // Adjust the width as needed
                                        height: 'auto', // Adjust the height as needed
                                    }} 
                                />
                            </Grid>
                            <Grid item xs={8} md={9}> {/* Adjust the xs and md values as needed */}
                                <DialogContentText 
                                    sx={{ 
                                        fontSize: '1.5rem', 
                                        fontWeight: 'bold', 
                                        color: '#5C4033',
                                    }}
                                >
                                    {selectedItem.name}
                                </DialogContentText>
                                <DialogContentText 
                                    sx={{ 
                                        color: '#5C4033',
                                    }}
                                >
                                    Size: {size} lb
                                </DialogContentText>
                                <DialogContentText 
                                    sx={{ 
                                        color: '#5C4033',
                                    }}
                                >
                                    Quantity: {quantity}
                                </DialogContentText>
                                <DialogContentText 
                                    sx={{ 
                                        color: '#5C4033', 
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Grind: {grind}
                                </DialogContentText>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={() => handleConfirmation(false)} 
                            variant="contained" 
                            color="primary" 
                            sx={{
                                borderRadius: '25px',
                                width: '50%',
                                height: '50px'
                            }}
                        >
                            Continue Browsing
                        </Button>
                        <Button 
                            onClick={() => handleConfirmation(true)}
                            variant="contained" 
                            color="primary" 
                            sx={{
                                borderRadius: '25px',
                                width: '50%',
                                height: '50px'
                            }}
                        >
                            View Cart
                        </Button>
                    </DialogActions>
            </Dialog>
        </div>      
    );
};

export default Bags;
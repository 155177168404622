import './App.css';
import React from 'react';
import Roots from './Routes';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51P5tkYAtOaLMPnmVS5FHOszLazqCzmbJXFGGGMeU9eiGkGAi9hcycMguRoQEqyYFkOTUNfZ3nGL4NVftERqvKfmq001TSX0V4T');
const App = () => {
  return (
    <div>
      <Elements stripe={stripePromise}>
        <Roots/>
      </Elements>
      
    </div>    
  );
};

export default App;
import React from 'react';
import {Typography, Divider} from '@mui/material';
import Bags from './Bags';
import Bottom from './Bottom';

const Shop = () => {
  return (
    <div>
      <div style={{ width: '100%', textAlign: 'center', marginTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
        <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
        <Typography variant="h4" component="h2" sx={{color: '#5C4033', fontSize: '24px', fontWeight: 'bold', margin: '0 10px', whiteSpace: 'nowrap'}}>
          Our Coffee Products
        </Typography>
        <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
      </div>
      <Bags all="True"/>
      <Bottom/>
    </div>
  );
};

export default Shop;
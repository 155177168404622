import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import XIcon from '@mui/icons-material/X';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Context from '../context/Context';
import * as React from 'react';
const Bottom = () => {
  const ctx = React.useContext(Context);
  return (
    <Box
    sx={{
        width: '100vw',
        position: 'relative',
        bottom: 0,
        minHeight: '100px', // Adjusted minHeight instead of fixed height
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: ctx.currWidth() <= 600 ? 'center' : 'flex-start',
        backgroundColor: '#DFCAA0',
        flexWrap: 'wrap', // Allow flex items to wrap
        gap: '10px', // Add gap between flex items
    }}
    >
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
            textAlign: 'center',
            }}
        >
            <Typography variant="h6" color="#5C4033" fontWeight="bold">Follow Us</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', mt: 1 }}>
                <IconButton
                color="inherit"
                sx={{
                    shape: 'circle',
                    border: '3px solid #5C4033',
                    '&:hover': {
                    color: '#fff',
                    backgroundColor: '#5C4033',
                    border: '3px solid #DFCAA0',
                    '& svg > path': {
                        color: '#DFCAA0',
                    },
                    },
                }}
                onClick={() => {
                    window.open('https://www.instagram.com/perfectpourespresso/', '_blank');
                }}
                >
                <InstagramIcon style={{ color: '#5C4033' }} />
                </IconButton>
                <IconButton
                color="inherit"
                sx={{
                    shape: 'circle',
                    border: '3px solid #5C4033',
                    '&:hover': {
                    color: '#fff',
                    backgroundColor: '#5C4033',
                    border: '3px solid #DFCAA0',
                    '& svg > path': {
                        color: '#DFCAA0',
                    },
                    },
                }}
                onClick={() => {
                    window.open('https://www.facebook.com/people/Perfect-Pour-Espresso/61559212642430/?mibextid=LQQJ4d', '_blank');
                }}
                >
                <FacebookIcon style={{ color: '#5C4033' }} />
                </IconButton>
                {/* <IconButton
                color="inherit"
                sx={{
                    shape: 'circle',
                    border: '3px solid #5C4033',
                    '&:hover': {
                    color: '#fff',
                    backgroundColor: '#5C4033',
                    border: '3px solid #DFCAA0',
                    '& svg > path': {
                        color: '#DFCAA0',
                    },
                    },
                }}
                onClick={() => {
                    window.open('https://www.x.com/', '_blank');
                }}
                >
                <XIcon style={{ color: '#5C4033' }} />
                </IconButton> */}
            </Box>
        </Box>
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
            textAlign: 'center',
            }}
        >
            <Typography variant="h6" color="#5C4033" fontWeight="bold">View our Reviews:</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>
                <Link href="https://www.yelp.com/" underline="hover" color="#5C4033" sx={{ display: 'flex', alignItems: 'center' }}>
                    Yelp
                </Link>
                <Link href="https://www.google.com/business/profile/" underline="hover" color="#5C4033" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    Google Reviews
                </Link>
            </Box>
        </Box>
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
            textAlign: 'center',
            }}
        >
            <Typography variant="h6" color="#5C4033" fontWeight="bold">Contact Information</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <PinDropIcon sx={{ mr: 1, color: '#5C4033'}}/>
                    <Typography variant="body2" color="#5C4033">San Jose, CA</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <EmailIcon sx={{ mr: 1, color: '#5C4033'}}/>
                    <Typography variant="body2" color="#5C4033">info@perfectpourespresso.com</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <PhoneIcon sx={{ mr: 1, color: '#5C4033'}}/>
                    <Typography variant="body2" color="#5C4033">(408) 483-3098</Typography>
                </Box>
            </Box>
        </Box>
    </Box>
  );
};

export default Bottom;

import React from 'react';
import Context from '../context/Context';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { MenuItem, Select, Input, Button} from '@mui/material';

const DesktopCart = () => {
  const ctx = React.useContext(Context);
  const navigate = useNavigate();
  
  const removeItemFromCart = (index) => {
    const rawCookie = Cookies.get('cart');
    let existingCart = rawCookie ? JSON.parse(rawCookie) : [];
    if (existingCart.length > 0) {
        existingCart.splice(index, 1);
    }
    Cookies.set('cart', JSON.stringify(existingCart));
    ctx.updateCart();
  };

  const updateItem = (index, valuetype, val) => {
    const rawCookie = Cookies.get('cart');
    let existingCart = rawCookie ? JSON.parse(rawCookie) : [];
    if (existingCart.length > 0) {
        existingCart[index][valuetype] = val;
    }
    Cookies.set('cart', JSON.stringify(existingCart));
    ctx.updateCart();
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: '50px' }}>
        <TableContainer sx={{ minWidth: '90vw', maxWidth: '90vw' }} component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '40px', fontSize: '1.2rem', color: '#5C4033' }}>Product</TableCell>
                        <TableCell align="center" sx={{ fontSize: '1.2rem', color: '#5C4033' }}>Grind</TableCell>
                        <TableCell align="center" sx={{ fontSize: '1.2rem', color: '#5C4033' }}>Price</TableCell>
                        <TableCell align="center" sx={{ fontSize: '1.2rem', color: '#5C4033' }}>Size</TableCell>
                        <TableCell align="center" sx={{ fontSize: '1.2rem', color: '#5C4033' }}>Quantity</TableCell>
                        <TableCell align="center" sx={{ fontSize: '1.2rem', color: '#5C4033' }}>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ctx.cartitems.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={require(`../CoffeeImages/${item.image_name}`)} alt={item.name} style={{ width: '90px', height: '90px', marginRight: '10px' }} />
                            <div style={{ marginLeft: '10px' }}>
                                <Typography variant="h6" fontWeight="bold" color= "#5C4033">{item.name}</Typography>
                                <Typography
                                    variant="body1"
                                    color="#5C4033"
                                    style={{
                                        marginTop: '-5px',
                                        textDecoration: 'underline',
                                        cursor: 'default',
                                        width: '10px'
                                    }}
                                    onMouseEnter={(e) => { e.target.style.color = '#7D5D4E'; e.target.style.cursor = 'pointer'; }}
                                    onMouseLeave={(e) => { e.target.style.color = '#5C4033'; e.target.style.cursor = 'default'; }}
                                    onClick={() => removeItemFromCart(index)}
                                >
                                    Remove
                                </Typography>
                            </div>
                        </TableCell>
                        <TableCell align="center">
                            <Typography color= "#5C4033">{item.grind}</Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography color= "#5C4033">${item.cost.toFixed(2)}</Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Select
                                labelId="size-label" 
                                value={item.size}
                                onChange={(e) => updateItem(index, 'size', e.target.value)}
                                sx={{
                                    color: '#5C4033', // Set text color to #5C4033
                                    minWidth: '80px',
                                    maxWidth: '80px'
                                }}
                            >
                                {ctx.sizes.map(option => (
                                    <MenuItem key={option} value={option}>{option} lb</MenuItem>
                                ))}
                            </Select>
                        </TableCell>
                        <TableCell align="center">
                            {/* Want to eventually come back and change the bottom of this */}
                            <Input
                                id="quantity"
                                type="number"
                                value={item.quantity}
                                onChange={(e) => updateItem(index, 'quantity', e.target.value)}
                                inputProps={{ min: 1 }}
                                sx={{
                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                    borderRadius: '4px',
                                    padding: '10px',
                                    color: '#5C4033',
                                    minWidth: '70px',
                                    maxWidth: '70px'
                                }}
                            />
                        </TableCell>
                        <TableCell align="center">${(item.cost * item.quantity * item.size).toFixed(2)}</TableCell>
                    </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={6} style={{ paddingTop: '20px', paddingLeft: '40px', paddingRight: '40px'}}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                                    <Typography variant="body1" color="#5C4033" style={{ marginTop: '-10px', marginBottom: '30px' }}>
                                        *Free shipping for orders 5 lb or more
                                    </Typography>
                                    <Typography 
                                        variant="body1" 
                                        color="#5C4033" 
                                        style={{ 
                                            marginTop: 'auto',
                                            color: '#5C4033', 
                                            textDecoration: 'underline' }}
                                        onMouseEnter={(e) => { e.target.style.color = '#7D5D4E'; e.target.style.cursor = 'pointer'; }}
                                        onMouseLeave={(e) => { e.target.style.color = '#5C4033'; e.target.style.cursor = 'default'; }}
                                        onClick={() => navigate('/Shop')}
                                        width="130px"
                                    >
                                        Continue Shopping
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h5" color="#5C4033">
                                            <span style={{ fontWeight: 'bold' }}>Total:</span> ${ctx.cartitems.reduce((total, item) => total + (item.cost * item.quantity * item.size), 0).toFixed(2)}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Button variant="contained" color="primary" style={{ borderRadius: '30px', width: '200px' }}
                                            onClick={() => navigate('/Checkout')}
                                        >
                                            Checkout
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
                
            </Table>
        </TableContainer>
    </div>
  );
};

export default DesktopCart;
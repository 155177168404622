import React from 'react';
import { Grid, Typography, Box, Button, Divider } from '@mui/material';
import coffeeImage from '../images/Story.jpg'; // Replace with the path to your image
import Bottom from './Bottom';
import { useNavigate } from "react-router-dom";

function OurStory() {
  const navigate = useNavigate();
  const handleBuyNowClick = () => {
    navigate("/Shop");
  };

  return (
    <div>
      <Box sx={{ padding: '20px' }}>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '5px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
          <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
          <Typography variant="h2" component="h2" sx={{color: '#5C4033', margin: '0 10px', whiteSpace: 'nowrap'}}>
            Our Story
          </Typography>
          <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" paragraph sx={{ color: '#5C4033' }}>
             Our Passion and Journey
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
              Our story begins with a passion for perfection and a deep love for coffee that has grown stronger over the years. At the heart of our journey is a commitment to being a full-service micro-roaster that delivers only the freshest, premium grade 100% Arabica coffee. Our beans are carefully selected and meticulously roasted to ensure every cup you serve is nothing short of exceptional.
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
              For many years, we've been the silent partner to espresso bars, catering busineses, coffee houses, and restaurants across the nation, helping them thrive by providing the finest coffee that elevates every moment. Our approach to roasting has stood the test of time, transcending trends and fads. We are not just roasters; we are craftsmen dedicated to the art of coffee. This dedication means that when you choose our coffee, you're not just buying a product; you're inheriting a piece of our legacy and expertise.
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
            Every bean tells a story, and every sip brings a legacy to life. Choose us, and let's make every cup an extraordinary experience. Together, we are not just masters of our craft—we are creators of moments, builders of connections, and curators of unforgettable experiences.
            </Typography>
            <Button variant="contained" sx={{ marginTop: '20px', backgroundColor: '#5C4033' }}
              onClick={handleBuyNowClick}
            >
              Browse Selection
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={coffeeImage}
              alt="Our Coffee"
              sx={{ width: '100%', height: '475px', cursor: 'pointer', objectFit: 'cover', borderRadius: '8px' }}
            />
          </Grid>
        </Grid>
      </Box>
      <div style={{ marginTop: '50px' }}>
        <Bottom/>
      </div>
      
    </div>
  );
}

export default OurStory;

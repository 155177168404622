import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, Button, Alert, Paper, Divider } from '@mui/material';
import contactImage from '../images/Contact.jpg'; // Replace with the path to your image
import Bottom from './Bottom';

const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [touched, setTouched] = useState({ name: false, email: false, message: false });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
  
    const validateEmail = (email) => {
      return /\S+@\S+\.\S+/.test(email);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!name || !email || !message) {
        setError('All fields are required.');
        return;
      }
  
      if (!validateEmail(email)) {
        setError('Please enter a valid email address.');
        return;
      }
  
      // Reset error state
      setError('');
  
      // Simulate API call
      try {
        const response = await fetch('https://3ksrmkonx7.execute-api.us-east-1.amazonaws.com/Current/CateringInqury', {
          method: 'POST',
          body: JSON.stringify({ name, email, message }),
          headers: { 'Content-Type': 'application/json'},
        });
  
        if (response.status === 200) {
          setSuccess(true);
        } else {
          setError('Failed to send message. Please try again later.');
        }
      } catch (err) {
        setError('Failed to send message. Please try again later.');
      }
    };
  
    return (
      <div>
        <Box sx={{ padding: '20px' }}>
          <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
            <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
              <Typography variant="h2" component="h2" sx={{color: '#5C4033', fontSize: '40px', margin: '0 10px', whiteSpace: 'nowrap'}}>
                Contact Us
              </Typography>
            <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
          </div>
          <Box
            component="img"
            src={contactImage}
            alt="Contact Us"
            sx={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '8px', marginBottom: '20px' }}
          />
          <Typography variant="h4" gutterBottom sx={{ color: '#5C4033', textAlign: 'center' }}>
            Contact us today to discuss your needs and how we can assist you. Whether it's for wholesale, or questions about our coffee, we are here to help.
          </Typography>
  
          {success ? (
            <Alert severity="success" sx={{ marginTop: '20px' }}>Message successfully sent!</Alert>
          ) : (
            <Box sx={{ marginTop: '20px' }}>
              
              <Paper elevation={3} sx={{ padding: '20px', backgroundColor: '#F0F0F0' }}>
                <Typography variant="body1" sx={{ color: '#5C4033', textAlign: 'center', marginBottom: '20px' }}>
                  Contact us at info@perfectpourespresso.com or (408)483-3098 or fill out the form below:
                </Typography>
                {error && <Alert severity="error" sx={{ marginBottom: '20px' }}>{error}</Alert>}
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Name"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onBlur={() => setTouched({ ...touched, name: true })}
                        error={touched.name && !name}
                        helperText={touched.name && !name ? 'Name is required' : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => setTouched({ ...touched, email: true })}
                        error={touched.email && (!email || !validateEmail(email))}
                        helperText={touched.email && (!email ? 'Email is required' : !validateEmail(email) ? 'Invalid email format' : '')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Message"
                        fullWidth
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onBlur={() => setTouched({ ...touched, message: true })}
                        error={touched.message && !message}
                        helperText={touched.message && !message ? 'Message is required' : ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button 
                        variant="contained" 
                        sx={{ backgroundColor: '#5C4033' }} 
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          )}
        </Box>
        <Bottom/>
      </div>
    );
};

export default ContactUs;
import React from 'react';
import {Box, Typography, Divider, Hidden} from '@mui/material';
import Context from '../context/Context';
const FirstLayout = () => {
  const ctx = React.useContext(Context);
  return (
    <Box display={ctx.currWidth() > 600 ? 'flex' : 'none'} id="outerBox" style={{ height: '75vh', minHeight: '550px'}}>
          <Box flex="1" id="leftbox" style={{ height: '100%', flexBasis: '50%'}}>
            <img
                src={require("../images/Latte.jpg")}
                style={{ maxWidth: '100%', height: '100%', objectFit: 'cover'}}
                id="firstimage"
                alt=""
            />
            <div style={{ position: 'relative', top: '-91%', left: '1%', width: '30vw', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h4" component="h2" sx={{color: 'black', fontSize: ctx.currWidth() > 950 ? '2vw' : '20px'}} fontWeight="bold" align="center">
                The perfect cup of coffee, in the comfort of your home
              </Typography>
              <button
                style={{
                  backgroundColor: '#808080',
                  color: '#FFF',
                  padding: '10px 20px',
                  borderRadius: '20px',
                  border: 'none',
                  cursor: 'pointer',
                  marginTop: '10px'
                }}
                onClick={() => window.location.href = '/shop'}
              >
                Browse selection
              </button>
            </div>
          </Box>
          <Box flex="2" display="flex" flexDirection="column" id="rightbox" style={{ flexBasis: '50%' }}>
            <Box flex="1" id="toprightbox" style={{ height: '50%' }}>
              <img
                  src={require("../images/Roaster.jpg")}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  id="toprightimage"
                  alt=""
              />
              <div style={{ position: 'relative', top: '-27%', right: '-1%', width: '40vw' }}>
                <Typography variant="h4" component="h2" sx={{color: '#FFF8E1', fontSize: ctx.currWidth() > 950 ? '2vw' : '20px'}} fontWeight="bold" align="center">
                  Our Journey, Your Perfect Cup: Discover our decades-long <a href="/OurStory" style={{ color: '#FFF8E1', textDecoration: 'underline' }}>process</a>
                </Typography>
              </div>
            </Box>
            <Box flex="1" id="bottomrightbox" style={{ height: '50%' }}>
              <img
                  src={require("../images/Services.jpg")}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  id="bottomrghtimage"
                  alt=""
              />
              <div style={{ position: 'relative', top: '-20%', right: '-4%', width: '40vw'}}>
                <Typography variant="h4" component="h2" sx={{color: 'black', fontSize: ctx.currWidth() > 950 ? '2vw' : '20px'}} fontWeight="bold" align="left">
                  Check out our <a href="/AdditionalServices" style={{ color: 'black', textDecoration: 'underline' }}>Additional Services</a>
                </Typography>
              </div>
            </Box>
          </Box>
        </Box>
  );
};

export default FirstLayout;
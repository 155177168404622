import React from 'react';
import Typography from '@mui/material/Typography';
import {Button, Box, Divider} from '@mui/material';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation
import Context from '../context/Context';
import Bottom from './Bottom';
import DesktopCart from './DesktopCart';
import MobileCart from './MobileCart';

const Cart = () => {
  const ctx = React.useContext(Context);
  return (
    <div>
      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
        <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
        <Typography variant="h3" component="h2" sx={{color: '#5C4033', fontSize: '40px', fontWeight: 'bold', margin: '0 10px', whiteSpace: 'nowrap'}}>
          Cart
        </Typography>
        <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
      </div>
      {ctx.cartsize === 0 ? (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center"
        sx={{
          height: {
            xs: '50vh',
            sm: '71.5vh',
          },
        }}
        >
          <Typography 
            variant="h1" 
            fontWeight="bold"
            sx={{ 
              color: '#5C4033',
              fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' }
            }}
          >Your cart is currently empty</Typography>
          <Button
            component={Link}
            to="/shop"
            variant="contained"
            color="primary"
            sx={{
              fontSize: { xs: '1rem', sm: '1rem', md: '1.5rem' },
              borderRadius: '9999px', // Make the border radius large to create rounded sides
            }}
          >
            Continue Shopping
          </Button>
        </Box>
      ) : 
        ctx.currWidth() > 830 ? (<DesktopCart/>) : (<MobileCart/>)
      }
      {ctx.cartsize !== 0 && <div 
        style={{ 
          height: ctx.currWidth() > 830 ? '270px' : ctx.currWidth() > 605 ? '200px' : '0px'
        }}
        ></div>}
      <Bottom/>
    </div>
  );
};

export default Cart;
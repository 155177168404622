import React from 'react';
import Grid from '@mui/material/Grid';
import CheckoutItems from './CheckoutItems';
import StripeCheckoutForm from './StripeCheckoutForm';
import Context from '../context/Context';
import { useNavigate } from "react-router-dom";
import Bottom from './Bottom';
const Checkout = () => {
  const ctx = React.useContext(Context);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (ctx.cartitems.length === 0) {
      navigate('/Home');
    }
  }, [ctx.cartitems, navigate]);
  // console.log(ctx.cartitems);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StripeCheckoutForm />
        </Grid>
        <Grid item xs={12} md={6}>
          <CheckoutItems />
        </Grid>
      </Grid>
      <Bottom/>
    </div>
    
  );
};

export default Checkout;
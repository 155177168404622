import React from 'react';
import Context from '../context/Context';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { MenuItem, Select, Input, Button, Grid, InputLabel} from '@mui/material';

const MobileCart = () => {
    const ctx = React.useContext(Context);
    const navigate = useNavigate();
    
    const removeItemFromCart = (index) => {
        const rawCookie = Cookies.get('cart');
        let existingCart = rawCookie ? JSON.parse(rawCookie) : [];
        if (existingCart.length > 0) {
            existingCart.splice(index, 1);
        }
        Cookies.set('cart', JSON.stringify(existingCart));
        ctx.updateCart();
    };

    const updateItem = (index, valuetype, val) => {
        const rawCookie = Cookies.get('cart');
        let existingCart = rawCookie ? JSON.parse(rawCookie) : [];
        if (existingCart.length > 0) {
            existingCart[index][valuetype] = val;
        }
        Cookies.set('cart', JSON.stringify(existingCart));
        ctx.updateCart();
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: '50px' }}>
            <TableContainer sx={{ minWidth: '90vw', maxWidth: '90vw' }} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ paddingLeft: '40px', fontSize: '1.2rem', color: '#5C4033' }}>Product</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ctx.cartitems.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Grid container spacing={2}>
                                    <Grid item xs={5} sm={2}>
                                        <img src={require(`../CoffeeImages/${item.image_name}`)} alt={item.name} style={{ width: '100%', height: 'auto' }} />
                                    </Grid>
                                    <Grid item xs={7} sm={4}>    
                                        <Typography variant="h6" fontWeight="bold" color="#5C4033">{item.name}</Typography>
                                        <Typography color= "#5C4033">Price: ${item.cost.toFixed(2)}</Typography>
                                        <Typography color= "#5C4033">Grind{item.grind}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <InputLabel 
                                            id="size-label"
                                            sx={{
                                                color: '#5C4033' // Set text color to #5C4033
                                            }}
                                        >
                                            Size
                                        </InputLabel>
                                        <Select
                                            labelId="size-label" 
                                            value={item.size}
                                            onChange={(e) => updateItem(index, 'size', e.target.value)}
                                            sx={{
                                                color: '#5C4033', // Set text color to #5C4033
                                                minWidth: '100%',
                                                maxWidth: '100%'
                                            }}
                                        >
                                            {ctx.sizes.map(option => (
                                                <MenuItem key={option} value={option}>{option} lb</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <InputLabel 
                                            htmlFor="quantity" 
                                            sx={{ 
                                                whiteSpace: 'normal', 
                                                overflowWrap: 'break-word',
                                                color: '#5C4033' // Set text color to #5C4033
                                            }}
                                        >
                                            Quantity
                                        </InputLabel>
                                        <Input
                                            id="quantity"
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => updateItem(index, 'quantity', e.target.value)}
                                            inputProps={{ min: 1 }}
                                            sx={{
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                borderRadius: '4px',
                                                padding: '10px',
                                                color: '#5C4033',
                                                minWidth: '100%',
                                                maxWidth: '100%'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', textAlign: 'right' }}>
                                        <Typography variant="h6" color="#5C4033">Total</Typography>
                                        <Typography color= "#5C4033">${(item.cost * item.quantity * item.size).toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{marginTop: ctx.currWidth() < 600 ? '-5px': '-50px',}}>
                                        <Typography
                                            variant="body1"
                                            color="#5C4033"
                                            style={{
                                            textDecoration: 'underline',
                                            cursor: 'default',
                                            width: '10px'
                                            }}
                                            onMouseEnter={(e) => { e.target.style.color = '#7D5D4E'; e.target.style.cursor = 'pointer'; }}
                                            onMouseLeave={(e) => { e.target.style.color = '#5C4033'; e.target.style.cursor = 'default'; }}
                                            onClick={() => removeItemFromCart(index)}
                                        >
                                            Remove
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        ))}
                        <TableRow>
                            <TableCell style={{ paddingTop: '20px'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" color="#5C4033" style={{ marginTop: '-10px', marginBottom: '30px' }}>
                                            *Free shipping for orders 5 lb or more
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {ctx.currWidth() > 500 ? (
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                                                <Typography variant="h5" color="#5C4033">
                                                    <span style={{ fontWeight: 'bold' }}>Total:</span> ${ctx.cartitems.reduce((total, item) => total + (item.cost * item.quantity * item.size), 0).toFixed(2)}
                                                </Typography>
                                                <Button variant="contained" color="primary" style={{ borderRadius: '30px', width: '200px' }} onClick={() => navigate('/Checkout')}>
                                                    Checkout
                                                </Button>
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography variant="h5" color="#5C4033">
                                                <span style={{ fontWeight: 'bold' }}>Total:</span> ${ctx.cartitems.reduce((total, item) => total + (item.cost * item.quantity * item.size), 0).toFixed(2)}
                                            </Typography>
                                            <Button variant="contained" color="primary" style={{ borderRadius: '30px', width: '200px' }} onClick={() => navigate('/Checkout')}>
                                                Checkout
                                            </Button>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography 
                                            variant="body1" 
                                            color="#5C4033" 
                                            style={{
                                                color: '#5C4033', 
                                                textDecoration: 'underline', 
                                                marginTop: ctx.currWidth() > 500 ? '-40px': '0px',
                                            }}
                                            onMouseEnter={(e) => { e.target.style.color = '#7D5D4E'; e.target.style.cursor = 'pointer'; }}
                                            onMouseLeave={(e) => { e.target.style.color = '#5C4033'; e.target.style.cursor = 'default'; }}
                                            onClick={() => navigate('/Shop')}
                                            width="130px"
                                        >
                                            Continue Shopping
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default MobileCart;
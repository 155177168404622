import React from 'react';
import Context from './Context';
import Cookies from 'js-cookie';
import {loadStripe} from '@stripe/stripe-js';

const winDims = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
});

/**
 * @param {object} props
 * @return {object} JSX
 */
function Provider(props) {
  const [global, setGlobal] = React.useState({
    'booted': false,
    'dimensions': winDims(),
    'threeimagechoice': false,
    'cartsize': 0,
    'cartitems': [],
    'grindOptions': {
      'Espresso': ['Whole Bean', 'Ground'],
      'Decaf Espresso': ['Whole Bean', 'Ground'],
      'Medium Dark Roast Blend': ['Whole Bean', 'Coarse', 'Regular', 'Elec Perk', 'Drip', 'Auto-Drip', 'Fine', 'Turkish'],
      'Decaf Medium Dark Roast Blend': ['Whole Bean', 'Coarse', 'Regular', 'Elec Perk', 'Drip', 'Auto-Drip', 'Fine', 'Turkish'],
      'Turkish Coffee': ['Whole Bean', 'Ground'],
      'Decaf Turkish Coffee': ['Whole Bean', 'Ground'],
    },
    'sizes': [1, 2, 5],
    'stripePromise': null
  });
  // source for global context design:
  // https://codeburst.io/beginner-guide-to-react-context-api-with-hook-usestate-4df7c3e08ad
  React.useEffect(() => {

    const handleStartUp = async () => {
      const rawCookie = Cookies.get('cart');
      const cart = rawCookie ? JSON.parse(rawCookie) : [];

      // Initialize total quantity
      let totalQuantity = 0;

      // Iterate through each item in the cart and sum up the quantities
      cart.forEach(item => {
          totalQuantity += item.quantity;
      });

      fetch("https://m7t02vzvdf.execute-api.us-east-1.amazonaws.com/dev/config")
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // console.log('Response JSON:', data); // Log the entire JSON response
        const body = JSON.parse(data.body);
        // console.log(body);
        const { publishableKey } = body;
        // console.log('Publishable Key:', publishableKey); // Log the publishableKey
        const stripePromise = loadStripe(publishableKey);
        // setStripePromise(stripePromise);
        // console.log(stripePromise);
        setGlobal({
          ...global,
          cartsize: totalQuantity,
          booted: true,
          cartitems: cart,
          stripePromise: stripePromise
        });
        
      })
      .catch((error) => {
        console.error("Error fetching publishableKey:", error);
      });

    }

    if (!global.booted) {
      handleStartUp();
    }
    
    const handleResize = () => {
      setGlobal({...global, dimensions: winDims()});
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    <Context.Provider value={{
      ...global,
      currWidth() {
        return this.dimensions.width;
      },
      showAppButtons() {
        return this.dimensions.width > 1000;
      },
      setState: (attr) => {
        setGlobal({...global, ...attr});
      },
      updateCart: () => {
        const rawCookie = Cookies.get('cart');
        const cart = rawCookie ? JSON.parse(rawCookie) : [];

        // Initialize total quantity
        let totalQuantity = 0;

        // Iterate through each item in the cart and sum up the quantities
        cart.forEach(item => {
            totalQuantity += item.quantity;
        });
        setGlobal({
          ...global,
          cartsize: totalQuantity,
          cartitems: cart
        });
      }
    }}>
      {props.children}
    </Context.Provider>
  );
};

export default Provider;

import React from 'react';
import {Box, Typography, Divider, Hidden} from '@mui/material';
import Context from '../context/Context'
const SecondLayout = () => {
  const ctx = React.useContext(Context);
  return (
    <Box display='flex' flexDirection="column" id="mainimagebox" style={{ height: '75vh', minHeight: '550px', display: 'flex', backgroundColor: 'rgb(151, 149, 149)' }}>
        <Box flex="1" id="topbox" style={{ display: 'flex', flexDirection: 'column', minHeight: '66.66%', maxHeight: '66.66%', minWidth: '100X', maxWidth: '100%' }}>
            <img
            src={require("../images/Latte.jpg")}
            style={{ maxWidth: '100%', minHeight: '100%', maxHeight: '100%', objectPosition: 'top', objectFit: 'fill'}} // I want to come back later and change this
            id="firstimage"
            alt=""
            />
            <div style={{ position: 'relative', top: '-91%', left: '1%', width: '30vw', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h4" component="h2" sx={{ color: 'black', fontSize: ctx.currWidth() > 950 ? '2vw' : '20px' }} fontWeight="bold" align="center">
                The perfect cup of coffee, in the comfort of your home
            </Typography>
            <button
                style={{
                backgroundColor: '#808080',
                color: '#FFF',
                padding: '10px 20px',
                borderRadius: '20px',
                border: 'none',
                cursor: 'pointer',
                marginTop: '10px'
                }}
                onClick={() => window.location.href = '/shop'}
            >
                Browse selection
            </button>
            </div>
        </Box>
        <Box flex="2" flexDirection="row" id="bottombox" style={{ display: 'flex', flexDirection: 'row', minHeight: '33.33%', maxHeight: '33.33%', minWidth: '100X', maxWidth: '100%', marginTop: '1px'}} overflow='hidden'>
            <Box flex="1" id="bottomleftbox" style={{ minHeight: '100%', maxHeight: '100%', minWidth: '50%', maxWidth: '50%', marginLeft: '.5px'}}>
                <img
                    src={require("../images/Roaster.jpg")}
                    style={{ width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(80%)'}}
                    id="bottomleftimage"
                    alt=""
                />
                <div style={{ position: 'relative', top: '-60px', width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 auto' }}>
                <Typography variant="h4" component="h2" sx={{color: 'white', fontSize: '20px'}} fontWeight="bold" align="center">
                    Our Journey, Your Perfect Cup: Discover our decades-long <a href="/OurStory" style={{ color: 'white', textDecoration: 'underline' }}>process</a>
                </Typography>
                </div>
            </Box>
            <Box flex="1" id="bottomrightbox" style={{ minHeight: '100%', maxHeight: '100%', minWidth: '50%', maxWidth: '50%', marginLeft: '1px'}}>
                <img
                    src={require("../images/Services.jpg")}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    id="smallthirdimage"
                    alt=""
                />
                <div style={{ position: 'relative', top: '-70px', width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 auto' }}>
                <Typography variant="h4" component="h2" sx={{color: 'white', fontSize: '20px'}} fontWeight="bold" align="center">
                    Check out our <a href="/AdditionalServices" style={{ color: 'white', textDecoration: 'underline' }}>Additional Services</a>
                </Typography>
                </div>
            </Box>
        </Box>
    </Box>  
    
  );
};

export default SecondLayout;
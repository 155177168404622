import React from 'react';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import TopBar from './components/TopBar';
import Home from './components/Home';
import OurStory from './components/OurStory';
import AdditionalServices from './components/AdditionalServices';
import Cart from './components/Cart';
import Shop from './components/Shop';
import Provider from './context/Provider';
import Checkout from './components/Checkout';
import ContactUs from './components/ContactUs';
import {ThemeProvider} from '@emotion/react';
import {createTheme} from '@mui/material/styles';

const Roots = () => {
    const theme = createTheme({
        typography: {
            fontFamily: 'Playfair Display, serif', // Set font to Playfair Display
            // Optionally, you can set other typography options here
          },
          palette: {
            primary: {
              main: '#5C4033', // Set color to #5C4033
            },
            // Optionally, you can set other palette colors here
          },
    });
    return (
        <BrowserRouter>
            <div>
                <ThemeProvider theme={theme}>
                    <Provider>
                        <TopBar/>
                        <Routes>
                            <Route path="/Home" element={<Home/>} />
                            <Route path="/Shop" element={<Shop/>}/>
                            <Route path="/OurStory" element={<OurStory/>}/>
                            <Route path="/AdditionalServices" element={<AdditionalServices/>}/>
                            <Route path="/Cart" element={<Cart/>}/>
                            <Route path="/Checkout" element={<Checkout/>}/>
                            <Route path="/ContactUs" element={<ContactUs/>}/>
                            <Route path="*" element={<Navigate to="/Home" />} />
                        </Routes>
                    </Provider>
                </ThemeProvider>
            </div>

            
        </BrowserRouter>
    );
};

export default Roots;
